import type {File, Image} from '@bttr-devs/nuxt-layer-sofie'
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: number | string; output: number | string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AdvertisementImage: { input: any; output: any; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  Email: { input: string; output: string; }
  Iban: { input: string; output: string; }
  Image: { input: Image; output: Image; }
  JSON: { input: any; output: any; }
  Mixed: { input: any; output: any; }
  Null: { input: null; output: null; }
  PrivateFile: { input: File; output: File; }
  PublicFile: { input: File; output: File; }
  Upload: { input: any; output: any; }
  Uuid: { input: string; output: string; }
};

export type Address = {
  __typename?: 'Address';
  capabilities: Array<Capability>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  geodata?: Maybe<Scalars['JSON']['output']>;
  house_number?: Maybe<Scalars['String']['output']>;
  house_number_suffix?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  street?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  house_number?: InputMaybe<Scalars['String']['input']>;
  house_number_suffix?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  zipcode?: InputMaybe<Scalars['String']['input']>;
};

export type Advertentie = {
  __typename?: 'Advertentie';
  active_image?: Maybe<Scalars['AdvertisementImage']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  externe_url: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  positie: Array<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ArticleFilters = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  company_id?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type Artikel = {
  __typename?: 'Artikel';
  active_category?: Maybe<Category>;
  active_image?: Maybe<Scalars['Image']['output']>;
  author: User_C0309034d81b26b4347db95e5291be1f;
  capabilities: Array<Capability>;
  categories: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  excerpt: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  /** Wanneer een bedrijf toegevoegd wordt, zal de auteur overschreven worden. */
  linked_company?: Maybe<Bedrijf>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  relevant_articles: Array<Artikel>;
  relevant_articles_count?: Maybe<Scalars['Int']['output']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type ArtikelPagination = {
  __typename?: 'ArtikelPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data: Array<Artikel>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type Bedrijf = {
  __typename?: 'Bedrijf';
  active_image?: Maybe<Scalars['Image']['output']>;
  branches: Array<Vestiging>;
  branches_count?: Maybe<Scalars['Int']['output']>;
  capabilities: Array<Capability>;
  categories: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  facebook?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  instagram?: Maybe<Scalars['String']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['Image']['output']>;
  package?: Maybe<Scalars['String']['output']>;
  package_label?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phone_deceased?: Maybe<Scalars['String']['output']>;
  pinterest?: Maybe<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  pv_id?: Maybe<Scalars['String']['output']>;
  review_average?: Maybe<Scalars['Float']['output']>;
  review_count?: Maybe<Scalars['Int']['output']>;
  reviews: Array<Review>;
  reviews_count?: Maybe<Scalars['Int']['output']>;
  seo?: Maybe<Seo>;
  tiktok?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
  x?: Maybe<Scalars['String']['output']>;
  youtube?: Maybe<Scalars['String']['output']>;
};

export type BranchFilters = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  has_package?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
  subCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Capability = {
  __typename?: 'Capability';
  name: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  active_image?: Maybe<Scalars['Image']['output']>;
  ancestors: Array<Category>;
  capabilities: Array<Capability>;
  children: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  descendants: Array<Category>;
  featured?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  name: Scalars['String']['output'];
  parent?: Maybe<Category>;
  parent_id?: Maybe<Scalars['Int']['output']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Evenement = {
  __typename?: 'Evenement';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  datum: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externe_url: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  locatie: Scalars['String']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type FavoriteUnion = Artikel | Vestiging;

export type Form = {
  __typename?: 'Form';
  fields: Array<FormField>;
  id: Scalars['Int']['output'];
  schema: Scalars['JSON']['output'];
  schema_v2: Scalars['Mixed']['output'];
  slug: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['Uuid']['output'];
};

export type FormData = {
  __typename?: 'FormData';
  fields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
};

export type FormField = {
  __typename?: 'FormField';
  fields: Array<FormField>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  regex?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unique?: Maybe<Scalars['Boolean']['output']>;
};

export type FormFieldAttachment = {
  attachment: Scalars['Upload']['input'];
  field_name: Scalars['String']['input'];
};

export enum LocaleEnum {
  Nl = 'nl'
}

export type Menu = {
  __typename?: 'Menu';
  items: Array<MenuItem>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  capability?: Maybe<Scalars['String']['output']>;
  children: Array<MenuItem>;
  /** @deprecated Use `capability` instead. */
  scope?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite?: Maybe<Scalars['Boolean']['output']>;
  changePassword?: Maybe<Scalars['Null']['output']>;
  createReview: Review;
  disableTwoFactor?: Maybe<Scalars['Boolean']['output']>;
  enableTwoFactor?: Maybe<Scalars['Boolean']['output']>;
  generateTwoFactorSecret?: Maybe<TwoFactorSecret>;
  login?: Maybe<Scalars['String']['output']>;
  logout?: Maybe<Scalars['Boolean']['output']>;
  registerCompany?: Maybe<Scalars['Null']['output']>;
  removeCompanyInspirationImage: Bedrijf;
  requestPasswordReset?: Maybe<Scalars['Boolean']['output']>;
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  submitCompanyContactForm?: Maybe<Scalars['Null']['output']>;
  submitForm?: Maybe<Scalars['Null']['output']>;
  updateAccountDetails?: Maybe<Scalars['Null']['output']>;
  updateCompanyContent?: Maybe<Scalars['Null']['output']>;
  updateCompanyDetails?: Maybe<Scalars['Null']['output']>;
  updateItemInFavorites: FavoriteUnion;
  updateOrCreateBranch?: Maybe<Scalars['Null']['output']>;
  uploadCompanyInspirationImage?: Maybe<Bedrijf>;
};


export type MutationAcceptInviteArgs = {
  email: Scalars['Email']['input'];
  invite: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  current_password: Scalars['String']['input'];
  new_password: Scalars['String']['input'];
  new_password_confirm: Scalars['String']['input'];
};


export type MutationCreateReviewArgs = {
  company: Scalars['ID']['input'];
  explanation: Scalars['String']['input'];
  score: Scalars['Int']['input'];
};


export type MutationEnableTwoFactorArgs = {
  otp: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  two_factor_code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterCompanyArgs = {
  company_name?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationRemoveCompanyInspirationImageArgs = {
  file_id: Scalars['Int']['input'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['Email']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  password_confirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSubmitCompanyContactFormArgs = {
  company: Scalars['Int']['input'];
  email: Scalars['Email']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationSubmitFormArgs = {
  attachments?: InputMaybe<Array<FormFieldAttachment>>;
  form_data: Scalars['String']['input'];
  form_id: Scalars['ID']['input'];
  g_recaptcha_response?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateAccountDetailsArgs = {
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  email: Scalars['Email']['input'];
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  name_insertion?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateCompanyContentArgs = {
  active_image?: InputMaybe<Scalars['Upload']['input']>;
  description: Scalars['String']['input'];
  images?: InputMaybe<Array<Scalars['Upload']['input']>>;
};


export type MutationUpdateCompanyDetailsArgs = {
  category: Array<Scalars['ID']['input']>;
  company_name: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  facebook?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_deceased?: InputMaybe<Scalars['String']['input']>;
  pinterest?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  x?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateItemInFavoritesArgs = {
  id: Scalars['Int']['input'];
  type: Scalars['String']['input'];
};


export type MutationUpdateOrCreateBranchArgs = {
  address: AddressInput;
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  branch?: InputMaybe<Scalars['ID']['input']>;
  branch_name: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  phone: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationUploadCompanyInspirationImageArgs = {
  file: Scalars['Upload']['input'];
};

export type Page = {
  __typename?: 'Page';
  active_image?: Maybe<Scalars['Image']['output']>;
  children?: Maybe<Array<Maybe<Page>>>;
  images: Array<Scalars['Image']['output']>;
  parent?: Maybe<Page>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  template?: Maybe<Template>;
  template_name: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  advertisement?: Maybe<Advertentie>;
  article?: Maybe<Artikel>;
  articles?: Maybe<ArtikelPagination>;
  branch?: Maybe<Vestiging>;
  branches?: Maybe<VestigingPagination>;
  categories: Array<Category>;
  category: Category;
  companies: Array<Bedrijf>;
  company?: Maybe<Bedrijf>;
  currentTenantInfo?: Maybe<Tenant_3af8041b6556776eda1312e7c8acaed7>;
  events?: Maybe<Array<Maybe<Evenement>>>;
  favorites: Array<FavoriteUnion>;
  form?: Maybe<Form>;
  me?: Maybe<User_07e75d37dfdb1fad1b23e74888c041b4>;
  menu?: Maybe<Menu>;
  menus: Array<Menu>;
  page?: Maybe<Page>;
  popularCategories: Array<Category>;
  reviews?: Maybe<ReviewPagination>;
  settings: Array<Setting>;
};


export type QueryAdvertisementArgs = {
  position: Scalars['String']['input'];
};


export type QueryArticleArgs = {
  slug: Scalars['String']['input'];
};


export type QueryArticlesArgs = {
  filters?: InputMaybe<ArticleFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBranchArgs = {
  slug: Scalars['String']['input'];
};


export type QueryBranchesArgs = {
  filters?: InputMaybe<BranchFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCategoriesArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCategoryArgs = {
  slug: Scalars['String']['input'];
};


export type QueryCompaniesArgs = {
  category?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCompanyArgs = {
  slug: Scalars['String']['input'];
};


export type QueryFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMenuArgs = {
  name: Scalars['String']['input'];
};


export type QueryMenusArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryPageArgs = {
  segments?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReviewsArgs = {
  company: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type Review = {
  __typename?: 'Review';
  auteur: User_C0309034d81b26b4347db95e5291be1f;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  score: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ReviewPagination = {
  __typename?: 'ReviewPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data: Array<Review>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Section = {
  __typename?: 'Section';
  button_label?: Maybe<Scalars['String']['output']>;
  button_target?: Maybe<Scalars['Boolean']['output']>;
  button_url?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  experience?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['Image']['output']>;
  image_alignment?: Maybe<Scalars['String']['output']>;
  image_slider: Array<Scalars['Image']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Seo = {
  __typename?: 'Seo';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_follow?: Maybe<Scalars['Boolean']['output']>;
  meta_index?: Maybe<Scalars['Boolean']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type Setting = {
  __typename?: 'Setting';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Mixed']['output']>;
};

export type Template = TemplateStandaard;

export type TemplateStandaard = {
  __typename?: 'TemplateStandaard';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  sections: Array<Section>;
  sections_count?: Maybe<Scalars['Int']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  tekst?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  domain: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  locales: Array<Scalars['String']['output']>;
  seo?: Maybe<Seo>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Tenant_3af8041b6556776eda1312e7c8acaed7 = {
  __typename?: 'Tenant_3af8041b6556776eda1312e7c8acaed7';
  active_image?: Maybe<Scalars['Image']['output']>;
  seo?: Maybe<Seo>;
  title: Scalars['String']['output'];
};

export type TwoFactorSecret = {
  __typename?: 'TwoFactorSecret';
  app: Scalars['String']['output'];
  email: Scalars['Email']['output'];
  secret: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  _?: Maybe<Scalars['String']['output']>;
};

export type User_07e75d37dfdb1fad1b23e74888c041b4 = {
  __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4';
  active_image?: Maybe<Scalars['Image']['output']>;
  avatar: Scalars['String']['output'];
  capabilities: Array<Capability>;
  company?: Maybe<Bedrijf>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['Email']['output'];
  first_name: Scalars['String']['output'];
  images: Array<Scalars['Image']['output']>;
  initials: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  name_insertion?: Maybe<Scalars['String']['output']>;
  newsletter?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  pv_id?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use roles field instead. */
  role?: Maybe<Role>;
  roles: Array<Role>;
  tenants: Array<Tenant>;
  tenants_count?: Maybe<Scalars['Int']['output']>;
  two_factor_enabled?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['Uuid']['output'];
};


export type User_07e75d37dfdb1fad1b23e74888c041b4AvatarArgs = {
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type User_C0309034d81b26b4347db95e5291be1f = {
  __typename?: 'User_c0309034d81b26b4347db95e5291be1f';
  avatar: Scalars['String']['output'];
  name: Scalars['String']['output'];
};


export type User_C0309034d81b26b4347db95e5291be1fAvatarArgs = {
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Vestiging = {
  __typename?: 'Vestiging';
  active_image?: Maybe<Scalars['Image']['output']>;
  address?: Maybe<Address>;
  capabilities: Array<Capability>;
  company: Bedrijf;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['Email']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['Image']['output']>;
  images: Array<Scalars['Image']['output']>;
  phone: Scalars['String']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  user_image?: Maybe<Scalars['Image']['output']>;
  username: Scalars['String']['output'];
};

export type VestigingPagination = {
  __typename?: 'VestigingPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data: Array<Vestiging>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type UpdateAccountDetailsMutationVariables = Exact<{
  first_name: Scalars['String']['input'];
  name_insertion?: InputMaybe<Scalars['String']['input']>;
  last_name: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['Upload']['input']>;
}>;


export type UpdateAccountDetailsMutation = { __typename?: 'Mutation', updateAccountDetails?: null | null };

export type UpdateCompanyContentMutationVariables = Exact<{
  active_image?: InputMaybe<Scalars['Upload']['input']>;
  description: Scalars['String']['input'];
  images?: InputMaybe<Array<Scalars['Upload']['input']> | Scalars['Upload']['input']>;
}>;


export type UpdateCompanyContentMutation = { __typename?: 'Mutation', updateCompanyContent?: null | null };

export type UpdateCompanyDetailsMutationVariables = Exact<{
  logo?: InputMaybe<Scalars['Upload']['input']>;
  company_name: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  phone_deceased?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  facebook?: InputMaybe<Scalars['String']['input']>;
  x?: InputMaybe<Scalars['String']['input']>;
  instagram?: InputMaybe<Scalars['String']['input']>;
  pinterest?: InputMaybe<Scalars['String']['input']>;
  linkedin?: InputMaybe<Scalars['String']['input']>;
  tiktok?: InputMaybe<Scalars['String']['input']>;
  youtube?: InputMaybe<Scalars['String']['input']>;
  category: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type UpdateCompanyDetailsMutation = { __typename?: 'Mutation', updateCompanyDetails?: null | null };

export type UpdateOrCreateBranchMutationVariables = Exact<{
  branch?: InputMaybe<Scalars['ID']['input']>;
  branch_name: Scalars['String']['input'];
  username: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  phone: Scalars['String']['input'];
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  address: AddressInput;
}>;


export type UpdateOrCreateBranchMutation = { __typename?: 'Mutation', updateOrCreateBranch?: null | null };

export type CreateReviewMutationVariables = Exact<{
  explanation: Scalars['String']['input'];
  score: Scalars['Int']['input'];
  company: Scalars['ID']['input'];
}>;


export type CreateReviewMutation = { __typename?: 'Mutation', createReview: { __typename?: 'Review', id: number, score: number, title: string, explanation?: string | null } };

export type RemoveCompanyInspirationImageMutationVariables = Exact<{
  fileId: Scalars['Int']['input'];
}>;


export type RemoveCompanyInspirationImageMutation = { __typename?: 'Mutation', removeCompanyInspirationImage: { __typename?: 'Bedrijf', active_image?: Image | null, created_at?: string | null, facebook?: string | null, id: number, instagram?: string | null, linkedin?: string | null, package_label?: string | null, pinterest?: string | null, tiktok?: string | null, title: string, type: string, x?: string | null, images: Array<Image>, branches: Array<{ __typename?: 'Vestiging', title: string, username: string, user_image?: Image | null, phone: string, email: string }> } };

export type UploadCompanyInspirationImageMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type UploadCompanyInspirationImageMutation = { __typename?: 'Mutation', uploadCompanyInspirationImage?: { __typename?: 'Bedrijf', active_image?: Image | null, created_at?: string | null, facebook?: string | null, id: number, instagram?: string | null, linkedin?: string | null, package_label?: string | null, pinterest?: string | null, tiktok?: string | null, title: string, type: string, x?: string | null, images: Array<Image>, branches: Array<{ __typename?: 'Vestiging', title: string, username: string, user_image?: Image | null, phone: string, email: string }> } | null };

export type RegisterCompanyMutationVariables = Exact<{
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  company_name?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['Email']['input'];
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type RegisterCompanyMutation = { __typename?: 'Mutation', registerCompany?: null | null };

export type SubmitCompanyContactFormMutationVariables = Exact<{
  name: Scalars['String']['input'];
  email: Scalars['Email']['input'];
  message: Scalars['String']['input'];
  company: Scalars['Int']['input'];
}>;


export type SubmitCompanyContactFormMutation = { __typename?: 'Mutation', submitCompanyContactForm?: null | null };

export type UpdateItemInFavoritesMutationVariables = Exact<{
  type: Scalars['String']['input'];
  id: Scalars['Int']['input'];
}>;


export type UpdateItemInFavoritesMutation = { __typename?: 'Mutation', updateItemInFavorites: { __typename?: 'Artikel', id: number, active_image?: Image | null, publish_at?: string | null, created_at?: string | null, slug: string, text: string, excerpt: string, title: string, author: { __typename?: 'User_c0309034d81b26b4347db95e5291be1f', avatar: string, name: string }, categories: Array<{ __typename?: 'Category', name: string }>, linked_company?: { __typename?: 'Bedrijf', id: number, title: string } | null } | { __typename?: 'Vestiging', id: number, title: string, slug: string, image?: Image | null, company: { __typename?: 'Bedrijf', package_label?: string | null }, address?: { __typename?: 'Address', city?: string | null } | null } };

export type AdvertisementQueryVariables = Exact<{
  position: Scalars['String']['input'];
}>;


export type AdvertisementQuery = { __typename?: 'Query', advertisement?: { __typename?: 'Advertentie', title: string, id: number, image?: any | null, url: string } | null };

export type ArticleQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type ArticleQuery = { __typename?: 'Query', article?: { __typename?: 'Artikel', active_image?: Image | null, created_at?: string | null, publish_at?: string | null, id: number, slug: string, text: string, title: string, active_category?: { __typename?: 'Category', name: string } | null, author: { __typename?: 'User_c0309034d81b26b4347db95e5291be1f', avatar: string, name: string }, linked_company?: { __typename?: 'Bedrijf', id: number, title: string } | null, categories: Array<{ __typename?: 'Category', id: number, name: string }>, relevant_articles: Array<{ __typename?: 'Artikel', active_image?: Image | null, publish_at?: string | null, id: number, slug: string, title: string, url: string }>, seo?: { __typename?: 'Seo', meta_description?: string | null, meta_follow?: boolean | null, meta_index?: boolean | null, meta_title?: string | null } | null } | null };

export type ArticlesQueryVariables = Exact<{
  filters?: InputMaybe<ArticleFilters>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ArticlesQuery = { __typename?: 'Query', articles?: { __typename?: 'ArtikelPagination', current_page: number, per_page: number, total: number, data: Array<{ __typename?: 'Artikel', active_image?: Image | null, id: number, slug: string, text: string, excerpt: string, title: string, publish_at?: string | null, created_at?: string | null, author: { __typename?: 'User_c0309034d81b26b4347db95e5291be1f', avatar: string, name: string }, categories: Array<{ __typename?: 'Category', name: string }>, linked_company?: { __typename?: 'Bedrijf', id: number, title: string } | null }> } | null };

export type BranchQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type BranchQuery = { __typename?: 'Query', branch?: { __typename?: 'Vestiging', id: number, active_image?: Image | null, created_at?: string | null, email: string, image?: Image | null, phone: string, title: string, user_image?: Image | null, username: string, address?: { __typename?: 'Address', id: number, city?: string | null, zipcode?: string | null, house_number?: string | null, house_number_suffix?: string | null, street: string } | null, company: { __typename?: 'Bedrijf', active_image?: Image | null, images: Array<Image>, description?: string | null, phone?: string | null, phone_deceased?: string | null, logo?: Image | null, email?: string | null, title: string, website?: string | null, facebook?: string | null, id: number, instagram?: string | null, linkedin?: string | null, package_label?: string | null, pinterest?: string | null, tiktok?: string | null, type: string, x?: string | null, review_count?: number | null, review_average?: number | null, categories: Array<{ __typename?: 'Category', id: number }>, reviews: Array<{ __typename?: 'Review', id: number, created_at?: string | null, title: string, explanation?: string | null, score: number, auteur: { __typename?: 'User_c0309034d81b26b4347db95e5291be1f', name: string, avatar: string } }> } } | null };

export type BranchesQueryVariables = Exact<{
  filters?: InputMaybe<BranchFilters>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type BranchesQuery = { __typename?: 'Query', branches?: { __typename?: 'VestigingPagination', current_page: number, last_page: number, per_page: number, total: number, data: Array<{ __typename?: 'Vestiging', id: number, title: string, slug: string, image?: Image | null, username: string, user_image?: Image | null, email: string, phone: string, company: { __typename?: 'Bedrijf', title: string, package_label?: string | null, images: Array<Image> }, address?: { __typename?: 'Address', id: number, city?: string | null, country?: string | null, house_number_suffix?: string | null, house_number?: string | null, zipcode?: string | null, street: string } | null }> } | null };

export type CategoriesQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type CategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: number, slug: string, name: string, text?: string | null, featured?: boolean | null, active_image?: Image | null, type?: string | null }> };

export type CategoryQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type CategoryQuery = { __typename?: 'Query', category: { __typename?: 'Category', id: number, name: string, slug: string, text?: string | null, children: Array<{ __typename?: 'Category', id: number, name: string }> } };

export type PopularCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type PopularCategoriesQuery = { __typename?: 'Query', popularCategories: Array<{ __typename?: 'Category', id: number, name: string, slug: string }> };

export type CompaniesQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type CompaniesQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'Bedrijf', active_image?: Image | null, id: number, package_label?: string | null, title: string, branches: Array<{ __typename?: 'Vestiging', title: string, username: string, user_image?: Image | null, email: string, phone: string, address?: { __typename?: 'Address', city?: string | null } | null }> }> };

export type CompanyQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type CompanyQuery = { __typename?: 'Query', company?: { __typename?: 'Bedrijf', active_image?: Image | null, created_at?: string | null, facebook?: string | null, id: number, images: Array<Image>, instagram?: string | null, linkedin?: string | null, package_label?: string | null, pinterest?: string | null, tiktok?: string | null, title: string, type: string, x?: string | null, branches: Array<{ __typename?: 'Vestiging', email: string, phone: string, title: string, user_image?: Image | null, username: string }>, seo?: { __typename?: 'Seo', meta_description?: string | null, meta_follow?: boolean | null, meta_index?: boolean | null, meta_title?: string | null } | null } | null };

export type EventsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsQuery = { __typename?: 'Query', events?: Array<{ __typename?: 'Evenement', active_image?: Image | null, description?: string | null, datum: string, externe_url: string, locatie: string, id: number, title: string } | null> | null };

export type FavoritesQueryVariables = Exact<{ [key: string]: never; }>;


export type FavoritesQuery = { __typename?: 'Query', favorites: Array<{ __typename?: 'Artikel', id: number, active_image?: Image | null, publish_at?: string | null, slug: string, text: string, excerpt: string, title: string, created_at?: string | null, author: { __typename?: 'User_c0309034d81b26b4347db95e5291be1f', avatar: string, name: string }, categories: Array<{ __typename?: 'Category', name: string }>, linked_company?: { __typename?: 'Bedrijf', id: number, title: string } | null } | { __typename?: 'Vestiging', id: number, title: string, slug: string, image?: Image | null, company: { __typename?: 'Bedrijf', package_label?: string | null }, address?: { __typename?: 'Address', city?: string | null } | null }> };

export type ReviewsQueryVariables = Exact<{
  company: Scalars['ID']['input'];
}>;


export type ReviewsQuery = { __typename?: 'Query', reviews?: { __typename?: 'ReviewPagination', current_page: number, per_page: number, total: number, data: Array<{ __typename?: 'Review', id: number, created_at?: string | null, title: string, explanation?: string | null, score: number, auteur: { __typename?: 'User_c0309034d81b26b4347db95e5291be1f', name: string, avatar: string } }> } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4', avatar: string, email: string, name: string, first_name: string, name_insertion?: string | null, last_name: string, newsletter?: boolean | null, phone?: string | null, company?: { __typename?: 'Bedrijf', images: Array<Image>, active_image?: Image | null, description?: string | null, phone?: string | null, phone_deceased?: string | null, email?: string | null, title: string, website?: string | null, facebook?: string | null, id: number, instagram?: string | null, linkedin?: string | null, logo?: Image | null, package_label?: string | null, pinterest?: string | null, tiktok?: string | null, type: string, x?: string | null, youtube?: string | null, branches: Array<{ __typename?: 'Vestiging', id: number, title: string, username: string, email: string, phone: string, address?: { __typename?: 'Address', zipcode?: string | null, house_number?: string | null, house_number_suffix?: string | null, city?: string | null, country?: string | null, street: string } | null }>, categories: Array<{ __typename?: 'Category', id: number, name: string }> } | null } | null };

export type PageQueryVariables = Exact<{
  segments: Scalars['String']['input'];
}>;


export type PageQuery = { __typename?: 'Query', page?: { __typename?: 'Page', active_image?: Image | null, slug: string, title: string, url: string, children?: Array<{ __typename?: 'Page', title: string, url: string } | null> | null, seo?: { __typename?: 'Seo', meta_description?: string | null, meta_follow?: boolean | null, meta_index?: boolean | null, meta_title?: string | null } | null, template?: { __typename?: 'TemplateStandaard', subtitle?: string | null, sections: Array<{ __typename?: 'Section', button_label?: string | null, button_target?: boolean | null, button_url?: string | null, experience?: string | null, hours?: string | null, image?: Image | null, image_alignment?: string | null, image_slider: Array<Image>, location?: string | null, text?: string | null, title: string, type?: string | null, id: number }> } | null } | null };


export const UpdateAccountDetailsDocument = gql`
    mutation updateAccountDetails($first_name: String!, $name_insertion: String, $last_name: String!, $email: Email!, $phone: String, $newsletter: Boolean, $avatar: Upload) {
  updateAccountDetails(
    first_name: $first_name
    name_insertion: $name_insertion
    last_name: $last_name
    email: $email
    phone: $phone
    newsletter: $newsletter
    avatar: $avatar
  )
}
    `;

/**
 * __useUpdateAccountDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountDetailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountDetailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAccountDetailsMutation({
 *   variables: {
 *     first_name: // value for 'first_name'
 *     name_insertion: // value for 'name_insertion'
 *     last_name: // value for 'last_name'
 *     email: // value for 'email'
 *     phone: // value for 'phone'
 *     newsletter: // value for 'newsletter'
 *     avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateAccountDetailsMutation(options: VueApolloComposable.UseMutationOptions<UpdateAccountDetailsMutation, UpdateAccountDetailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateAccountDetailsMutation, UpdateAccountDetailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateAccountDetailsMutation, UpdateAccountDetailsMutationVariables>(UpdateAccountDetailsDocument, options);
}
export type UpdateAccountDetailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateAccountDetailsMutation, UpdateAccountDetailsMutationVariables>;
export const UpdateCompanyContentDocument = gql`
    mutation updateCompanyContent($active_image: Upload, $description: String!, $images: [Upload!]) {
  updateCompanyContent(
    description: $description
    images: $images
    active_image: $active_image
  )
}
    `;

/**
 * __useUpdateCompanyContentMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyContentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyContentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCompanyContentMutation({
 *   variables: {
 *     active_image: // value for 'active_image'
 *     description: // value for 'description'
 *     images: // value for 'images'
 *   },
 * });
 */
export function useUpdateCompanyContentMutation(options: VueApolloComposable.UseMutationOptions<UpdateCompanyContentMutation, UpdateCompanyContentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateCompanyContentMutation, UpdateCompanyContentMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateCompanyContentMutation, UpdateCompanyContentMutationVariables>(UpdateCompanyContentDocument, options);
}
export type UpdateCompanyContentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateCompanyContentMutation, UpdateCompanyContentMutationVariables>;
export const UpdateCompanyDetailsDocument = gql`
    mutation updateCompanyDetails($logo: Upload, $company_name: String!, $email: Email!, $phone: String, $phone_deceased: String, $website: String, $facebook: String, $x: String, $instagram: String, $pinterest: String, $linkedin: String, $tiktok: String, $youtube: String, $category: [ID!]!) {
  updateCompanyDetails(
    logo: $logo
    company_name: $company_name
    email: $email
    phone: $phone
    phone_deceased: $phone_deceased
    website: $website
    facebook: $facebook
    x: $x
    instagram: $instagram
    pinterest: $pinterest
    linkedin: $linkedin
    tiktok: $tiktok
    youtube: $youtube
    category: $category
  )
}
    `;

/**
 * __useUpdateCompanyDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyDetailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyDetailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCompanyDetailsMutation({
 *   variables: {
 *     logo: // value for 'logo'
 *     company_name: // value for 'company_name'
 *     email: // value for 'email'
 *     phone: // value for 'phone'
 *     phone_deceased: // value for 'phone_deceased'
 *     website: // value for 'website'
 *     facebook: // value for 'facebook'
 *     x: // value for 'x'
 *     instagram: // value for 'instagram'
 *     pinterest: // value for 'pinterest'
 *     linkedin: // value for 'linkedin'
 *     tiktok: // value for 'tiktok'
 *     youtube: // value for 'youtube'
 *     category: // value for 'category'
 *   },
 * });
 */
export function useUpdateCompanyDetailsMutation(options: VueApolloComposable.UseMutationOptions<UpdateCompanyDetailsMutation, UpdateCompanyDetailsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateCompanyDetailsMutation, UpdateCompanyDetailsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateCompanyDetailsMutation, UpdateCompanyDetailsMutationVariables>(UpdateCompanyDetailsDocument, options);
}
export type UpdateCompanyDetailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateCompanyDetailsMutation, UpdateCompanyDetailsMutationVariables>;
export const UpdateOrCreateBranchDocument = gql`
    mutation updateOrCreateBranch($branch: ID, $branch_name: String!, $username: String!, $email: Email!, $phone: String!, $avatar: Upload, $address: AddressInput!) {
  updateOrCreateBranch(
    branch: $branch
    branch_name: $branch_name
    username: $username
    email: $email
    phone: $phone
    avatar: $avatar
    address: $address
  )
}
    `;

/**
 * __useUpdateOrCreateBranchMutation__
 *
 * To run a mutation, you first call `useUpdateOrCreateBranchMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrCreateBranchMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOrCreateBranchMutation({
 *   variables: {
 *     branch: // value for 'branch'
 *     branch_name: // value for 'branch_name'
 *     username: // value for 'username'
 *     email: // value for 'email'
 *     phone: // value for 'phone'
 *     avatar: // value for 'avatar'
 *     address: // value for 'address'
 *   },
 * });
 */
export function useUpdateOrCreateBranchMutation(options: VueApolloComposable.UseMutationOptions<UpdateOrCreateBranchMutation, UpdateOrCreateBranchMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateOrCreateBranchMutation, UpdateOrCreateBranchMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateOrCreateBranchMutation, UpdateOrCreateBranchMutationVariables>(UpdateOrCreateBranchDocument, options);
}
export type UpdateOrCreateBranchMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateOrCreateBranchMutation, UpdateOrCreateBranchMutationVariables>;
export const CreateReviewDocument = gql`
    mutation createReview($explanation: String!, $score: Int!, $company: ID!) {
  createReview(explanation: $explanation, score: $score, company: $company) {
    id
    score
    title
    explanation
  }
}
    `;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateReviewMutation({
 *   variables: {
 *     explanation: // value for 'explanation'
 *     score: // value for 'score'
 *     company: // value for 'company'
 *   },
 * });
 */
export function useCreateReviewMutation(options: VueApolloComposable.UseMutationOptions<CreateReviewMutation, CreateReviewMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateReviewMutation, CreateReviewMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateReviewMutation, CreateReviewMutationVariables>(CreateReviewDocument, options);
}
export type CreateReviewMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateReviewMutation, CreateReviewMutationVariables>;
export const RemoveCompanyInspirationImageDocument = gql`
    mutation removeCompanyInspirationImage($fileId: Int!) {
  removeCompanyInspirationImage(file_id: $fileId) {
    active_image
    created_at
    facebook
    id
    instagram
    linkedin
    package_label
    pinterest
    tiktok
    title
    type
    x
    images
    branches {
      title
      username
      user_image
      phone
      email
    }
  }
}
    `;

/**
 * __useRemoveCompanyInspirationImageMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyInspirationImageMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyInspirationImageMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRemoveCompanyInspirationImageMutation({
 *   variables: {
 *     fileId: // value for 'fileId'
 *   },
 * });
 */
export function useRemoveCompanyInspirationImageMutation(options: VueApolloComposable.UseMutationOptions<RemoveCompanyInspirationImageMutation, RemoveCompanyInspirationImageMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RemoveCompanyInspirationImageMutation, RemoveCompanyInspirationImageMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RemoveCompanyInspirationImageMutation, RemoveCompanyInspirationImageMutationVariables>(RemoveCompanyInspirationImageDocument, options);
}
export type RemoveCompanyInspirationImageMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RemoveCompanyInspirationImageMutation, RemoveCompanyInspirationImageMutationVariables>;
export const UploadCompanyInspirationImageDocument = gql`
    mutation uploadCompanyInspirationImage($file: Upload!) {
  uploadCompanyInspirationImage(file: $file) {
    active_image
    created_at
    facebook
    id
    instagram
    linkedin
    package_label
    pinterest
    tiktok
    title
    type
    x
    images
    branches {
      title
      username
      user_image
      phone
      email
    }
  }
}
    `;

/**
 * __useUploadCompanyInspirationImageMutation__
 *
 * To run a mutation, you first call `useUploadCompanyInspirationImageMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyInspirationImageMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUploadCompanyInspirationImageMutation({
 *   variables: {
 *     file: // value for 'file'
 *   },
 * });
 */
export function useUploadCompanyInspirationImageMutation(options: VueApolloComposable.UseMutationOptions<UploadCompanyInspirationImageMutation, UploadCompanyInspirationImageMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UploadCompanyInspirationImageMutation, UploadCompanyInspirationImageMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UploadCompanyInspirationImageMutation, UploadCompanyInspirationImageMutationVariables>(UploadCompanyInspirationImageDocument, options);
}
export type UploadCompanyInspirationImageMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UploadCompanyInspirationImageMutation, UploadCompanyInspirationImageMutationVariables>;
export const RegisterCompanyDocument = gql`
    mutation registerCompany($first_name: String!, $last_name: String!, $company_name: String, $email: Email!, $newsletter: Boolean) {
  registerCompany(
    first_name: $first_name
    last_name: $last_name
    company_name: $company_name
    email: $email
    newsletter: $newsletter
  )
}
    `;

/**
 * __useRegisterCompanyMutation__
 *
 * To run a mutation, you first call `useRegisterCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRegisterCompanyMutation({
 *   variables: {
 *     first_name: // value for 'first_name'
 *     last_name: // value for 'last_name'
 *     company_name: // value for 'company_name'
 *     email: // value for 'email'
 *     newsletter: // value for 'newsletter'
 *   },
 * });
 */
export function useRegisterCompanyMutation(options: VueApolloComposable.UseMutationOptions<RegisterCompanyMutation, RegisterCompanyMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RegisterCompanyMutation, RegisterCompanyMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RegisterCompanyMutation, RegisterCompanyMutationVariables>(RegisterCompanyDocument, options);
}
export type RegisterCompanyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RegisterCompanyMutation, RegisterCompanyMutationVariables>;
export const SubmitCompanyContactFormDocument = gql`
    mutation submitCompanyContactForm($name: String!, $email: Email!, $message: String!, $company: Int!) {
  submitCompanyContactForm(
    name: $name
    email: $email
    message: $message
    company: $company
  )
}
    `;

/**
 * __useSubmitCompanyContactFormMutation__
 *
 * To run a mutation, you first call `useSubmitCompanyContactFormMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCompanyContactFormMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSubmitCompanyContactFormMutation({
 *   variables: {
 *     name: // value for 'name'
 *     email: // value for 'email'
 *     message: // value for 'message'
 *     company: // value for 'company'
 *   },
 * });
 */
export function useSubmitCompanyContactFormMutation(options: VueApolloComposable.UseMutationOptions<SubmitCompanyContactFormMutation, SubmitCompanyContactFormMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SubmitCompanyContactFormMutation, SubmitCompanyContactFormMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SubmitCompanyContactFormMutation, SubmitCompanyContactFormMutationVariables>(SubmitCompanyContactFormDocument, options);
}
export type SubmitCompanyContactFormMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SubmitCompanyContactFormMutation, SubmitCompanyContactFormMutationVariables>;
export const UpdateItemInFavoritesDocument = gql`
    mutation updateItemInFavorites($type: String!, $id: Int!) {
  updateItemInFavorites(type: $type, id: $id) {
    ... on Artikel {
      id
      active_image
      publish_at
      created_at
      slug
      text
      excerpt
      title
      author {
        avatar
        name
      }
      categories {
        name
      }
      linked_company {
        id
        title
      }
    }
    ... on Vestiging {
      id
      title
      slug
      image
      company {
        package_label
      }
      address {
        city
      }
    }
  }
}
    `;

/**
 * __useUpdateItemInFavoritesMutation__
 *
 * To run a mutation, you first call `useUpdateItemInFavoritesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemInFavoritesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateItemInFavoritesMutation({
 *   variables: {
 *     type: // value for 'type'
 *     id: // value for 'id'
 *   },
 * });
 */
export function useUpdateItemInFavoritesMutation(options: VueApolloComposable.UseMutationOptions<UpdateItemInFavoritesMutation, UpdateItemInFavoritesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateItemInFavoritesMutation, UpdateItemInFavoritesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateItemInFavoritesMutation, UpdateItemInFavoritesMutationVariables>(UpdateItemInFavoritesDocument, options);
}
export type UpdateItemInFavoritesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateItemInFavoritesMutation, UpdateItemInFavoritesMutationVariables>;
export const AdvertisementDocument = gql`
    query advertisement($position: String!) {
  advertisement(position: $position) {
    image: active_image
    title
    id
    url: externe_url
  }
}
    `;

/**
 * __useAdvertisementQuery__
 *
 * To run a query within a Vue component, call `useAdvertisementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvertisementQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAdvertisementQuery({
 *   position: // value for 'position'
 * });
 */
export function useAdvertisementQuery(variables: AdvertisementQueryVariables | VueCompositionApi.Ref<AdvertisementQueryVariables> | ReactiveFunction<AdvertisementQueryVariables>, options: VueApolloComposable.UseQueryOptions<AdvertisementQuery, AdvertisementQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AdvertisementQuery, AdvertisementQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AdvertisementQuery, AdvertisementQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<AdvertisementQuery, AdvertisementQueryVariables>(AdvertisementDocument, variables, options);
}
export function useAdvertisementLazyQuery(variables?: AdvertisementQueryVariables | VueCompositionApi.Ref<AdvertisementQueryVariables> | ReactiveFunction<AdvertisementQueryVariables>, options: VueApolloComposable.UseQueryOptions<AdvertisementQuery, AdvertisementQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AdvertisementQuery, AdvertisementQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<AdvertisementQuery, AdvertisementQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<AdvertisementQuery, AdvertisementQueryVariables>(AdvertisementDocument, variables, options);
}
export type AdvertisementQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AdvertisementQuery, AdvertisementQueryVariables>;
export const ArticleDocument = gql`
    query article($slug: String!) {
  article(slug: $slug) {
    active_category {
      name
    }
    active_image
    author {
      avatar
      name
    }
    linked_company {
      id
      title
    }
    categories {
      id
      name
    }
    created_at
    publish_at
    id
    relevant_articles {
      active_image
      publish_at
      id
      slug
      title
      url
    }
    seo {
      meta_description
      meta_follow
      meta_index
      meta_title
    }
    slug
    text
    title
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a Vue component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useArticleQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useArticleQuery(variables: ArticleQueryVariables | VueCompositionApi.Ref<ArticleQueryVariables> | ReactiveFunction<ArticleQueryVariables>, options: VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, variables, options);
}
export function useArticleLazyQuery(variables?: ArticleQueryVariables | VueCompositionApi.Ref<ArticleQueryVariables> | ReactiveFunction<ArticleQueryVariables>, options: VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, variables, options);
}
export type ArticleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ArticleQuery, ArticleQueryVariables>;
export const ArticlesDocument = gql`
    query articles($filters: ArticleFilters, $per_page: Int, $page: Int) @cache(public: true, ttl: 1800) {
  articles(filters: $filters, per_page: $per_page, page: $page) {
    current_page
    data {
      active_image
      id
      slug
      text
      excerpt
      title
      author {
        avatar
        name
      }
      categories {
        name
      }
      publish_at
      created_at
      linked_company {
        id
        title
      }
    }
    per_page
    total
  }
}
    `;

/**
 * __useArticlesQuery__
 *
 * To run a query within a Vue component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useArticlesQuery({
 *   filters: // value for 'filters'
 *   per_page: // value for 'per_page'
 *   page: // value for 'page'
 * });
 */
export function useArticlesQuery(variables: ArticlesQueryVariables | VueCompositionApi.Ref<ArticlesQueryVariables> | ReactiveFunction<ArticlesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, variables, options);
}
export function useArticlesLazyQuery(variables: ArticlesQueryVariables | VueCompositionApi.Ref<ArticlesQueryVariables> | ReactiveFunction<ArticlesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, variables, options);
}
export type ArticlesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ArticlesQuery, ArticlesQueryVariables>;
export const BranchDocument = gql`
    query branch($slug: String!) {
  branch(slug: $slug) {
    id
    active_image
    created_at
    email
    image
    phone
    title
    user_image
    username
    address {
      id
      street: title
      city
      zipcode
      house_number
      house_number_suffix
    }
    company {
      active_image
      categories {
        id
      }
      images
      description
      phone
      phone_deceased
      logo
      email
      title
      website
      facebook
      id
      instagram
      linkedin
      package_label
      pinterest
      tiktok
      title
      type
      x
      reviews {
        id
        created_at
        title
        explanation
        score
        auteur {
          name
          avatar
        }
      }
      review_count
      review_average
    }
  }
}
    `;

/**
 * __useBranchQuery__
 *
 * To run a query within a Vue component, call `useBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBranchQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useBranchQuery(variables: BranchQueryVariables | VueCompositionApi.Ref<BranchQueryVariables> | ReactiveFunction<BranchQueryVariables>, options: VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<BranchQuery, BranchQueryVariables>(BranchDocument, variables, options);
}
export function useBranchLazyQuery(variables?: BranchQueryVariables | VueCompositionApi.Ref<BranchQueryVariables> | ReactiveFunction<BranchQueryVariables>, options: VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<BranchQuery, BranchQueryVariables>(BranchDocument, variables, options);
}
export type BranchQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BranchQuery, BranchQueryVariables>;
export const BranchesDocument = gql`
    query branches($filters: BranchFilters, $limit: Int, $page: Int, $per_page: Int) {
  branches(filters: $filters, limit: $limit, page: $page, per_page: $per_page) {
    current_page
    data {
      id
      title
      slug
      image
      company {
        title
        package_label
        images
      }
      username
      user_image
      email
      phone
      address {
        id
        city
        street: title
        country
        house_number_suffix
        house_number
        zipcode
      }
    }
    last_page
    per_page
    total
  }
}
    `;

/**
 * __useBranchesQuery__
 *
 * To run a query within a Vue component, call `useBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBranchesQuery({
 *   filters: // value for 'filters'
 *   limit: // value for 'limit'
 *   page: // value for 'page'
 *   per_page: // value for 'per_page'
 * });
 */
export function useBranchesQuery(variables: BranchesQueryVariables | VueCompositionApi.Ref<BranchesQueryVariables> | ReactiveFunction<BranchesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<BranchesQuery, BranchesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BranchesQuery, BranchesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BranchesQuery, BranchesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<BranchesQuery, BranchesQueryVariables>(BranchesDocument, variables, options);
}
export function useBranchesLazyQuery(variables: BranchesQueryVariables | VueCompositionApi.Ref<BranchesQueryVariables> | ReactiveFunction<BranchesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<BranchesQuery, BranchesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BranchesQuery, BranchesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BranchesQuery, BranchesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<BranchesQuery, BranchesQueryVariables>(BranchesDocument, variables, options);
}
export type BranchesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BranchesQuery, BranchesQueryVariables>;
export const CategoriesDocument = gql`
    query categories($type: String) @cache(public: true) {
  categories(type: $type) {
    id
    slug
    name
    text
    featured
    active_image
    type
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a Vue component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCategoriesQuery({
 *   type: // value for 'type'
 * });
 */
export function useCategoriesQuery(variables: CategoriesQueryVariables | VueCompositionApi.Ref<CategoriesQueryVariables> | ReactiveFunction<CategoriesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, variables, options);
}
export function useCategoriesLazyQuery(variables: CategoriesQueryVariables | VueCompositionApi.Ref<CategoriesQueryVariables> | ReactiveFunction<CategoriesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, variables, options);
}
export type CategoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CategoriesQuery, CategoriesQueryVariables>;
export const CategoryDocument = gql`
    query category($slug: String!) @cache(public: true) {
  category(slug: $slug) {
    id
    name
    slug
    text
    children {
      id
      name
    }
  }
}
    `;

/**
 * __useCategoryQuery__
 *
 * To run a query within a Vue component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCategoryQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useCategoryQuery(variables: CategoryQueryVariables | VueCompositionApi.Ref<CategoryQueryVariables> | ReactiveFunction<CategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<CategoryQuery, CategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategoryQuery, CategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategoryQuery, CategoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, variables, options);
}
export function useCategoryLazyQuery(variables?: CategoryQueryVariables | VueCompositionApi.Ref<CategoryQueryVariables> | ReactiveFunction<CategoryQueryVariables>, options: VueApolloComposable.UseQueryOptions<CategoryQuery, CategoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategoryQuery, CategoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategoryQuery, CategoryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, variables, options);
}
export type CategoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CategoryQuery, CategoryQueryVariables>;
export const PopularCategoriesDocument = gql`
    query popularCategories @cache(public: true) {
  popularCategories {
    id
    name
    slug
  }
}
    `;

/**
 * __usePopularCategoriesQuery__
 *
 * To run a query within a Vue component, call `usePopularCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePopularCategoriesQuery();
 */
export function usePopularCategoriesQuery(options: VueApolloComposable.UseQueryOptions<PopularCategoriesQuery, PopularCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PopularCategoriesQuery, PopularCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PopularCategoriesQuery, PopularCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PopularCategoriesQuery, PopularCategoriesQueryVariables>(PopularCategoriesDocument, {}, options);
}
export function usePopularCategoriesLazyQuery(options: VueApolloComposable.UseQueryOptions<PopularCategoriesQuery, PopularCategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PopularCategoriesQuery, PopularCategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PopularCategoriesQuery, PopularCategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PopularCategoriesQuery, PopularCategoriesQueryVariables>(PopularCategoriesDocument, {}, options);
}
export type PopularCategoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PopularCategoriesQuery, PopularCategoriesQueryVariables>;
export const CompaniesDocument = gql`
    query companies($query: String, $category: ID, $limit: Int, $page: Int) {
  companies(query: $query, category: $category, limit: $limit, page: $page) {
    active_image
    id
    package_label
    title
    branches {
      title
      username
      user_image
      email
      phone
      address {
        city
      }
    }
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a Vue component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompaniesQuery({
 *   query: // value for 'query'
 *   category: // value for 'category'
 *   limit: // value for 'limit'
 *   page: // value for 'page'
 * });
 */
export function useCompaniesQuery(variables: CompaniesQueryVariables | VueCompositionApi.Ref<CompaniesQueryVariables> | ReactiveFunction<CompaniesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<CompaniesQuery, CompaniesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CompaniesQuery, CompaniesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CompaniesQuery, CompaniesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, variables, options);
}
export function useCompaniesLazyQuery(variables: CompaniesQueryVariables | VueCompositionApi.Ref<CompaniesQueryVariables> | ReactiveFunction<CompaniesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<CompaniesQuery, CompaniesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CompaniesQuery, CompaniesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CompaniesQuery, CompaniesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, variables, options);
}
export type CompaniesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyDocument = gql`
    query company($slug: String!) {
  company(slug: $slug) {
    active_image
    branches {
      email
      phone
      title
      user_image
      username
    }
    created_at
    facebook
    id
    images
    instagram
    linkedin
    package_label
    pinterest
    seo {
      meta_description
      meta_follow
      meta_index
      meta_title
    }
    tiktok
    title
    type
    x
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a Vue component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useCompanyQuery(variables: CompanyQueryVariables | VueCompositionApi.Ref<CompanyQueryVariables> | ReactiveFunction<CompanyQueryVariables>, options: VueApolloComposable.UseQueryOptions<CompanyQuery, CompanyQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CompanyQuery, CompanyQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CompanyQuery, CompanyQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, variables, options);
}
export function useCompanyLazyQuery(variables?: CompanyQueryVariables | VueCompositionApi.Ref<CompanyQueryVariables> | ReactiveFunction<CompanyQueryVariables>, options: VueApolloComposable.UseQueryOptions<CompanyQuery, CompanyQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CompanyQuery, CompanyQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CompanyQuery, CompanyQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, variables, options);
}
export type CompanyQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CompanyQuery, CompanyQueryVariables>;
export const EventsDocument = gql`
    query events {
  events {
    active_image
    description
    datum
    externe_url
    locatie
    id
    title
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a Vue component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEventsQuery();
 */
export function useEventsQuery(options: VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, {}, options);
}
export function useEventsLazyQuery(options: VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, {}, options);
}
export type EventsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EventsQuery, EventsQueryVariables>;
export const FavoritesDocument = gql`
    query favorites {
  favorites {
    ... on Artikel {
      id
      active_image
      publish_at
      slug
      text
      excerpt
      title
      author {
        avatar
        name
      }
      categories {
        name
      }
      created_at
      linked_company {
        id
        title
      }
    }
    ... on Vestiging {
      id
      title
      slug
      image
      company {
        package_label
      }
      address {
        city
      }
    }
  }
}
    `;

/**
 * __useFavoritesQuery__
 *
 * To run a query within a Vue component, call `useFavoritesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoritesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFavoritesQuery();
 */
export function useFavoritesQuery(options: VueApolloComposable.UseQueryOptions<FavoritesQuery, FavoritesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FavoritesQuery, FavoritesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FavoritesQuery, FavoritesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FavoritesQuery, FavoritesQueryVariables>(FavoritesDocument, {}, options);
}
export function useFavoritesLazyQuery(options: VueApolloComposable.UseQueryOptions<FavoritesQuery, FavoritesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FavoritesQuery, FavoritesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FavoritesQuery, FavoritesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FavoritesQuery, FavoritesQueryVariables>(FavoritesDocument, {}, options);
}
export type FavoritesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FavoritesQuery, FavoritesQueryVariables>;
export const ReviewsDocument = gql`
    query reviews($company: ID!) {
  reviews(company: $company) {
    current_page
    data {
      id
      created_at
      title
      explanation
      score
      auteur {
        name
        avatar
      }
    }
    per_page
    total
  }
}
    `;

/**
 * __useReviewsQuery__
 *
 * To run a query within a Vue component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReviewsQuery({
 *   company: // value for 'company'
 * });
 */
export function useReviewsQuery(variables: ReviewsQueryVariables | VueCompositionApi.Ref<ReviewsQueryVariables> | ReactiveFunction<ReviewsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ReviewsQuery, ReviewsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsQuery, ReviewsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsQuery, ReviewsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, variables, options);
}
export function useReviewsLazyQuery(variables?: ReviewsQueryVariables | VueCompositionApi.Ref<ReviewsQueryVariables> | ReactiveFunction<ReviewsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ReviewsQuery, ReviewsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReviewsQuery, ReviewsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReviewsQuery, ReviewsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, variables, options);
}
export type ReviewsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ReviewsQuery, ReviewsQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    avatar
    email
    name
    first_name
    name_insertion
    last_name
    newsletter
    phone
    company {
      images
      active_image
      description
      phone
      phone_deceased
      email
      title
      website
      facebook
      id
      instagram
      linkedin
      logo
      package_label
      pinterest
      tiktok
      title
      type
      x
      youtube
      branches {
        id
        title
        username
        email
        phone
        address {
          zipcode
          street: title
          house_number
          house_number_suffix
          city
          country
        }
      }
      categories {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export function useMeLazyQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const PageDocument = gql`
    query page($segments: String!) @cache(public: true) {
  page(segments: $segments) {
    active_image
    children {
      title
      url
    }
    seo {
      meta_description
      meta_follow
      meta_index
      meta_title
    }
    slug
    template {
      ... on TemplateStandaard {
        sections {
          button_label
          button_target
          button_url
          experience
          hours
          image
          image_alignment
          image_slider
          location
          text
          title
          type
          id
        }
        subtitle
      }
    }
    title
    url
  }
}
    `;

/**
 * __usePageQuery__
 *
 * To run a query within a Vue component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePageQuery({
 *   segments: // value for 'segments'
 * });
 */
export function usePageQuery(variables: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export function usePageLazyQuery(variables?: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export type PageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PageQuery, PageQueryVariables>;